import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: localStorage.getItem("token") || null,
    URL: process.env.VUE_APP_API_BASE_ENDPOINT,
    user: localStorage.getItem("user") || null,
    pageTitle: "",
  },
  getters: {
    loggedIn: (state) => state.token !== null,
    pageTitle: (state) => state.pageTitle,
  },
  mutations: {
    SET_TOKEN(state, token) {
      state.token = token;
    },

    DESTROY_TOKEN(state) {
      state.token = null;
    },
    SET_USER(state, user) {
      state.user = user;
    },
    SET_PAGE_TITLE(state, title) {
      state.pageTitle = title;
    },
  },
  actions: {
    login({ commit }) {
      return commit("SET_TOKEN", localStorage.getItem("token"));
    },
    userId({ commit }) {
      return commit("SET_USER", localStorage.getItem("user"));
    },
    destroyToken({ getters, commit }) {
      if (getters.loggedIn) {
        localStorage.clear();
        commit("DESTROY_TOKEN");
      }
    },
    fetchUser({ commit }, data) {
      return commit("SET_USER", data);
    },
    setPageTitle({ commit }, data) {
      return commit("SET_PAGE_TITLE", data);
    },
  },
  modules: {},
});
