import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "MyAssignedTicketsView",
    component: () => import("../views/MyAssignedTickets.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/my-tickets",
    name: "MyTicketsView",
    component: () => import("../views/MyTicketsView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/chat-tickets",
    name: "ChatTicketsView",
    component: () => import("../views/ChatTickets.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/closed-tickets",
    name: "ClosedTicketsView",
    component: () => import("../views/ClosedTickets.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/ticket/:id",
    name: "TicketDetails",
    component: () => import("../views/TicketDetails.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/create-ticket",
    name: "CreateTicketView",
    component: () => import("../views/CreateTicket.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/all-tickets",
    name: "AllTicketsView",
    component: () => import("../views/AllTickets.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/followup",
    name: "FollowUpTickets",
    component: () => import("../views/FollowUpTickets.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/users",
    name: "UsersView",
    component: () => import("../views/Users.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/login",
    name: "LoginView",
    component: () => import("../views/Login.vue"),
    meta: { requiresVisitor: true },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
