<template>
  <v-app>
    <v-navigation-drawer
      app
      class="nav-drawer"
      v-model="drawer"
      v-if="hasToken"
    >
      <div class="logo">
        <img alt="Vue logo" src="./assets/logo.png" />
      </div>
      <v-list>
        <v-list-item-group>
          <router-link to="/create-ticket">
            <div class="create-ticket">
              <v-btn color="#dd6f40" block>
                <v-icon color="white">mdi-plus</v-icon>
                <span class="create-text">Create Ticket</span>
              </v-btn>
            </div>
          </router-link>

          <!-- <router-link to="/">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-chart-bar</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Statistics</v-list-item-title>
            </v-list-item>
          </router-link> -->

          <router-link to="/all-tickets" v-if="canViewIf('ADMIN')">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-ticket-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>All Tickets</v-list-item-title>
            </v-list-item>
          </router-link>

          <router-link to="/">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-ticket-account</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Assigned To Me</v-list-item-title>
              <v-badge
                inline
                :content="badges?.assigned_to_me || '0'"
                color="warning"
                class="menu"
              ></v-badge>
            </v-list-item>
          </router-link>

          <router-link to="/my-tickets">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-ticket</v-icon>
              </v-list-item-icon>
              <v-list-item-title>My Tickets</v-list-item-title>
            </v-list-item>
          </router-link>

          <router-link to="/followup">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-access-point-plus</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Follow Up</v-list-item-title>
            </v-list-item>
          </router-link>

          <router-link to="/chat-tickets">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-chat</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Chat Tickets</v-list-item-title>
              <v-badge
                inline
                :content="badges?.chat_tickets || '0'"
                color="warning"
                class="menu"
              ></v-badge>
            </v-list-item>
          </router-link>

          <router-link to="/closed-tickets">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-close-circle</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Closed Tickets</v-list-item-title>
            </v-list-item>
          </router-link>

          <router-link to="/users" v-if="canViewIf('ADMIN')">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-account-group</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Users</v-list-item-title>
            </v-list-item>
          </router-link>

          <!-- <router-link to="/agents" v-if="canViewIf('ADMIN')">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-face-agent</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Agents Activity</v-list-item-title>
            </v-list-item>
          </router-link> -->

          <div class="bottom-nav">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-account</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ user.name }}</v-list-item-title>
                <v-list-item-subtitle>
                  {{ user?.department }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="logout">
              <v-list-item-icon>
                <v-icon>mdi-logout</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Logout</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app class="app-bar">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <h3>{{ $store.state.pageTitle }}</h3>
    </v-app-bar>
    <v-main>
      <!-- Provides the application the proper gutter -->
      <v-container fluid>
        <!-- If using vue-router -->
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<style lang="scss">
.menu .v-badge__wrapper .warning {
  background: #262626 !important;
  color: #fb8c00 !important;
  font-weight: lighter;
}

#app {
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.app-bar {
  box-shadow: none !important;
  background: #fff !important;
}

.v-item-group {
  height: calc(100vh - 124px);
}

.bottom-nav {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.theme--light.v-list-item:not(.v-list-item--disabled) .v-list-item__subtitle,
.theme--light.v-list-item:not(.v-list-item--disabled)
  .v-list-item__action-text {
  color: rgb(251 140 0);
}

nav {
  a {
    text-decoration: none;

    &.router-link-exact-active {
      text-transform: none !important;
      font-weight: bold;

      .v-list-item {
        // background: #dd6f40;
        background: #ffdcce;

        .v-list-item,
        .v-list-item__title,
        .v-list-item__icon i {
          color: #dd6f40 !important;
        }
      }
    }
  }
}

.nav-drawer {
  background: #2f3640 !important;

  .v-list-item,
  .v-list-item__icon i {
    color: #fff !important;
  }
}

.logo {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  width: 100%;
  margin-bottom: 24px;
}

.v-list-item--link:before {
  background: none !important;
  content: "";
}

.create-ticket {
  padding: 16px 30px;
}

.create-ticket .create-text {
  color: #fff;
}
</style>
<script>
export default {
  data: () => ({
    hasToken: localStorage.getItem("token") != null,
    user: {
      name: "Issue Tracker User",
      department: "Department",
    },
    badges: {},
    drawer: true,
  }),
  created() {
    const user = JSON.parse(localStorage.getItem("user"));
    this.user = {
      name: user?.name,
      department: user?.department?.name,
    };

    setInterval(() => {
      this.getTicketBadges();
    }, 10 * 1000);

    this.getTicketBadges();
  },
  methods: {
    logout() {
      this.$http.post("/auth/logout").then(() => {
        this.$store.dispatch("destroyToken").then(() => {
          this.$router.push({ name: "LoginView" });
          window.location.reload();
        });
      });
    },
    canViewIf(type) {
      const userType = JSON.parse(localStorage.getItem("user")).type;
      return type == userType;
    },
    getTicketBadges() {
      this.$http.get("/ticket/get-ticket-badges").then((response) => {
        this.badges = response.data;
      });
    },
  },
};
</script>
