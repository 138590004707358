import axios from "axios";
import Vue from "vue";
import VueMeta from "vue-meta";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
import { TiptapVuetifyPlugin } from "tiptap-vuetify";
import { vue3Debounce } from "vue-debounce";
import VueToast from "vue-toast-notification";
// don't forget to import CSS styles
import "vue-toast-notification/dist/theme-sugar.css";

import "tiptap-vuetify/dist/main.css";

/**
 * General Configuration
 */
Vue.use(VueMeta);
Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: "mdi",
});

Vue.use(vue3Debounce);
Vue.use(VueToast);

Vue.prototype.$http = axios;
Vue.config.productionTip = false;
axios.defaults.baseURL = store.state.URL;
axios.defaults.headers.common["Authorization"] = "Bearer " + store.state.token;

Vue.filter("str_limit", function (value, size = 60) {
  if (!value) return "";
  value = value.toString();

  if (value.length <= size) {
    return value;
  }
  return value.substr(0, size) + "...";
});

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.loggedIn) {
      next({
        name: "LoginView",
      });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.requiresVisitor)) {
    if (store.getters.loggedIn) {
      next({
        name: "MyAssignedTicketsView",
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

new Vue({
  vuetify,
  router,
  store,
  data: () => ({
    notificationOpen: false,
  }),
  created() {
    axios.interceptors.response.use(
      (response) => response,
      (err) => {
        if (err.response.status == 401) {
          if (!this.notificationOpen) {
            this.$toast.open({
              message:
                "Your session has expired, we will try to restart it for you",
              type: "info",
              position: "top",
              duration: 50000,
            });

            this.notificationOpen = true;
          }
        }

        return Promise.reject(err);
      }
    );
  },
  methods: {
    refreshToken() {
      this.$http
        .post("/auth/refresh")
        .then((response) => {
          this.$toast.clear();
          console.log("res", response);

          localStorage.setItem("token", response.data.token);
          this.$store.dispatch("login", response.data.token);

          this.$toast.open({
            message: "Success, your session was restarted successfully",
            type: "success",
            position: "top",
          });

          setTimeout(() => {
            window.location.reload();
          }, 3000);
        })
        .catch((err) => {
          this.$toast.clear();
          this.$toast.open({
            message:
              "We could not restart your session, you have to login again.",
            type: "error",
            position: "top",
          });

          this.$store.dispatch("destroyToken").then(() => {
            this.$router.push({ name: "LoginView" });
          });

          // setTimeout(() => {
          //   window.location.reload();
          // }, 1000);

          console.log("err", err.response.data.message);
        });
    },
  },
  render: (h) => h(App),
}).$mount("#app");
